<template>
  <div class="flex-auto column" style="margin-bottom: 0;">
    <div class="padding-bottom-10 border-bottom bold">浏览记录</div>
    <div class="table-vertical-top" ref="main">
      <el-table
        v-if="listInfo.list.length"
        :show-header="false"
        :data="listInfo.list"
        stripe
        style="width: 100%"
      >
      <el-table-column label="商品信息"  min-width="200">
        <template slot-scope="props">
          <div class="flex-row link" @click="$root.jump({path:'/mall/prodDetail',query:{q:crypto.encrypt({spid:props.row.spid,hd_id:props.row.hd_id})},blank:true})">
            <div><el-image class="list-item-pic border" :src="props.row.img_url" fit="contain">
              <div slot="error" class="flex-column flex-auto align-items-center justify-center">
                <img src="@/assets/images/no-pic.png" height="60px"/>
              </div>
            </el-image></div>
            <div class="flex-auto margin-left-10 font-size-12 line-height-medium">
              <div class="font-size-16 bold margin-bottom-5">{{props.row.spmch}}</div>
              <div class="color-sub">{{props.row.shengccj}}</div>
              <div class="color-sub">{{props.row.shpgg}}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="库存">
        <template slot-scope="props">
          库存：{{props.row.pcjcshl_str}}
        </template>
      </el-table-column>
      <el-table-column label="价格">
        <template slot-scope="props">
          价格：<span class="color-orange">￥{{props.row.hshsj}}</span>
        </template>
      </el-table-column>
      </el-table>
      <div v-if="listInfo.loading" class="text-align-center color-sub margin-tb-10"><i class="el-icon-loading"></i> 加载中</div>
      <div v-if="listInfo.noMore&&listInfo.list.length" class="text-align-center color-sub margin-tb-10">没有更多啦！</div>
      <el-empty v-if="!listInfo.list.length&&listInfo.page==1&&!listInfo.loading" description="暂无数据"></el-empty>
    </div>
  </div>
</template>

<script>
import crypto from "@/common/crypto";
export default {
  components: { },
  name: 'BrowsingHistory',
  data() {
    return {
      crypto,
      listInfo:{
        loading:false,
        noMore:false,
        page:1,
        pageSize:20,
        list:[]
      },
      bodyScrollHeight:0
    }
  },
  computed:{
    
  },
  methods:{
    //获取列表
    getList(){
      this.listInfo.loading=true
      let params= {
        ...this.$store.state.basicParams,
        page:this.listInfo.page,
        page_size:this.listInfo.pageSize,
      }
      this.$instance.get('/product/get_user_product_view_history',{params})
      .then(res=>{
        this.listInfo.loading=false
        if(res.data.code==0){
          this.listInfo.list=this.listInfo.list.concat(res.data.data.list)
          this.$nextTick(()=>{
            window.scrollTo(0,this.bodyScrollHeight)
          })
          //是否全部加载完成
          if(res.data.data.list.length<this.listInfo.pageSize){
            this.listInfo.noMore=true
          }else{this.listInfo.page+=1}
          //如果有更多增加滚动加载侦听
          if(this.listInfo.page==2){
            document.addEventListener('scroll', this.loadMore)
          }
        }
      })
      .catch(()=> {
        this.listInfo.loading=false
      }); 
    },
    //加载更多
    loadMore(){
      this.bodyScrollHeight=document.documentElement.scrollTop || document.body.scrollTop
      this.$root.scrollLoad({
        dom:this.$refs.main,
        allow:!this.listInfo.loading&&!this.listInfo.noMore,
        offset:0,
        callBack:this.getList,
      })
    },
  },
  created(){
    this.getList()
  },
  mounted(){ 
    
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.loadMore);
  },
  watch: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
 .list-item-pic{
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background: #fff;
    overflow: hidden;
  }
</style>
<style>

</style>
